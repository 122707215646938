import i18n from 'i18next';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import resources from 'Translation';

const languages = {
  VI: 'vi',
  EN: 'en',
};

const supportLanguage = (language) => {
  var supporting = Object.values(languages).includes(language);
  if (language && supporting && i18n.language !== language) {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }
  return supporting;
};

i18n.use(initReactI18next).init({
  resources,
  debug: false,
  initImmediate: false,
  useSuspense: false,
  lng: localStorage.getItem('language') || languages.VI,
});

export { languages, supportLanguage, i18n, I18nextProvider };
